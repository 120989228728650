import { ItemData } from "../../types";

export const itemsDifference = (
  fullItemsList: ItemData[],
  newItemsList: ItemData[]
) => {
  const newItems = fullItemsList?.filter((item: ItemData) => {
    const filteredItems = newItemsList?.find(
      (newItem: ItemData) => newItem.erpPartNumber === item.erpPartNumber
    );
    if (filteredItems) {
      return false;
    }
    return true;
  });
  return newItems;
};
